import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { EMPTY_TAX_MAPPING_ENTITY, TaxMappingEntity } from "domain/entity/TaxMapping/TaxMappingEntity";
import { ConsortiumRepository } from "domain/repository/ChargeDetailMaintenance/ConsortiumRepo";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { CustomerRepository } from "domain/repository/Company/CustomerRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffTypeRepository } from "domain/repository/TariffCode/TariffTypeRepo";
import { TaxMappingRepository } from "domain/repository/TaxMapping/TaxMappingRepo";
import _ from "lodash";
import { vatPaymentReferenceDateDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { taxMappingCSVColumnMapping, taxMappingCSVDateColumnMapping, taxMappingCSVNumberColumnMapping } from "presentation/constant/TaxMapping/TaxMappingConstant";
import { DropdownProps } from "presentation/model/DropdownProps";
import { TaxMappingModel } from "presentation/model/TaxMapping/TaxMappingModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface TaxMappingVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<TaxMappingModel>> | ((value: SetStateAction<TaxMappingModel>) => void),
    ],
    taxMappingRepo: TaxMappingRepository,
    chargeTypeRepo: ChargeTypeRepository,
    masterDataRepo: MasterDataRepository,
    companyRepo: CompanyRepository,
    parameterDetailRepo: ParameterDetailRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    customerRepo: CustomerRepository,
    consortiumRepo: ConsortiumRepository,
    tariffTypeRepo: TariffTypeRepository,
}

export const TaxMappingVM = ({ dispatch, taxMappingRepo, chargeTypeRepo, customerRepo, tariffTypeRepo,
    standardTariffCodeRepo, masterDataRepo, companyRepo, parameterDetailRepo, consortiumRepo
}: TaxMappingVMProps) => {
    const [taxMappingDispatch] = dispatch;

    const loadDropdownOption = async () => {

        await tariffTypeRepo.getAllActiveTariffTypes().then(
            tariffs => {
                let newTariffs = _.orderBy(tariffs, ["tariffType"]);
                let tariffTypeDropdownOption = newTariffs?.map((tariff) => ({
                    dropdownLabel: tariff.tariffType,
                    tagLabel: tariff.tariffType,
                    value: tariff.tariffType,
                })) ?? []
                taxMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOption,
                        chargeTariffTypeDropdownOptions: tariffTypeDropdownOption,
                    }
                }))
            }
        );

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {

                let newTariffs = _.orderBy(tariffs, ["tariffType", "tariffCode"]);
                // let tariffTypeDropdownOptions: DropdownProps[] = [];
                let tariffCodeDropdownOptions: { [key: string]: DropdownProps[] } = {};
                // let allTariffCodeeDropdownOptions: DropdownProps[] = [];

                newTariffs?.forEach(tariff => {
                    // const isTariffTypeExisted = tariffTypeDropdownOptions.find(t =>
                    //     t.value === tariff.tariffType);
                    // if (!isTariffTypeExisted) {
                    //     tariffTypeDropdownOptions.push({
                    //         dropdownLabel: tariff.tariffType,
                    //         tagLabel: tariff.tariffType,
                    //         value: tariff.tariffType,
                    //     })
                    // }

                    // const isTariffCodeExisted = allTariffCodeeDropdownOptions.find(t =>
                    //     t.value === tariff.tariffCode);

                    // if (!isTariffCodeExisted) {
                    //     allTariffCodeeDropdownOptions.push({
                    //         dropdownLabel: tariff.tariffCode,
                    //         tagLabel: tariff.tariffCode,
                    //         value: tariff.tariffCode,
                    //     })
                    // }

                    if (tariff.tariffCode) {
                        if (!tariffCodeDropdownOptions[tariff.tariffType]) {
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })

                    }
                })

                taxMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        // tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                        // chargeTariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        chargeTariffCodeDropdownOptions: tariffCodeDropdownOptions,
                    }
                }))
            }
        )

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOption: DropdownProps[] = [];
                let subChargeTypeDropdownOption: { [key: string]: DropdownProps[] } = {};
                newChargeTypes?.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOption.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOption.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOption[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOption[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOption[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                taxMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOption,
                        subChargeTypeDropdownOptions: subChargeTypeDropdownOption
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.SERVICE).then(
            serviceCodes => {
                const serviceCodeDropdownOptions = serviceCodes?.map((serviceCode) => ({
                    dropdownLabel: serviceCode.code,
                    tagLabel: serviceCode.code,
                    value: serviceCode.code,
                })) ?? []

                taxMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        serviceCodeDropdownOptions: serviceCodeDropdownOptions
                    }
                }))
            }
        );

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOption = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOption = _.orderBy(companyCodeDropdownOption, "dropdownLabel");

                taxMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyCodeDropdownOptions: companyCodeDropdownOption,
                    }
                }))
            }
        )
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("AR_TAX_CODE").then(
            async taxCodeList => {
                const vatList: { [key: string]: number } = {};
                let newTaxCodeList = _.orderBy(taxCodeList, ["parameterDtlCode"]);
                for (const dtl of newTaxCodeList) {
                    await parameterDetailRepo.getParameterThirdDtlByParaCodeAndParaDtlCode("AR_TAX_CODE", dtl.parameterDtlCode).then((data) => {
                        for (const entity of data) {
                            if (entity.parameterDtlCode === "TAX_PERCENTAGE") {
                                const pattern = /[^0-9.]/g;
                                const cleanedValue = entity.parameterDtlDesc?.replace(pattern, '').trim();
                                vatList[dtl.parameterDtlCode] = Number(cleanedValue);
                            }
                        }
                    });

                }
                const taxCodeDropdownOption = newTaxCodeList?.map((taxCode) => ({
                    dropdownLabel: taxCode.parameterDtlCode,
                    tagLabel: taxCode.parameterDtlCode,
                    value: taxCode.parameterDtlCode,
                })) ?? []
                const transTypeList: { [key: string]: string } = {};
                newTaxCodeList?.forEach(entity => {
                    transTypeList[entity.parameterDtlCode] = entity.parameterDtlDesc ?? '';
                });
                taxMappingDispatch(prevState => ({
                    ...prevState,
                    transactionTypeList: transTypeList,
                    vatList: vatList,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        taxCodeDropdownOptions: taxCodeDropdownOption
                    }
                }))
            }
        )
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("ARTICLE_STMT_CODE").then(
            vatArticleStatementCodeList => {
                let newList = _.orderBy(vatArticleStatementCodeList, ["parameterDtlCode"]);
                const vatArticleStatementCodeDropdownOption = newList?.map((vatArticleStatementCode) => ({
                    dropdownLabel: vatArticleStatementCode.parameterDtlCode,
                    tagLabel: vatArticleStatementCode.parameterDtlCode,
                    value: vatArticleStatementCode.parameterDtlCode,
                })) ?? []

                const artStatementCodeList: { [key: string]: string } = {};
                newList?.forEach(entity => {
                    artStatementCodeList[entity.parameterDtlCode] = entity.parameterDtlDesc ?? '';
                });
                taxMappingDispatch(prevState => ({
                    ...prevState,
                    articleStatementList: artStatementCodeList,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        vatArticleStatementCodeDropdownOptions: vatArticleStatementCodeDropdownOption
                    }
                }))
            }
        )
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("REGION").then(
            regionList => {
                const regionDropdownOption = regionList?.map((region) => ({
                    dropdownLabel: region.parameterDtlCode,
                    tagLabel: region.parameterDtlCode,
                    value: region.parameterDtlCode,
                })) ?? []

                taxMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        regionDropdownOptions: regionDropdownOption
                    }
                }))
            }
        )
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("STORAGE_TAX_TIER_GROUP").then(
            groupList => {
                const groupDropdownOption = groupList?.map((group) => ({
                    dropdownLabel: group.parameterDtlCode,
                    tagLabel: group.parameterDtlCode,
                    value: group.parameterDtlCode,
                })) ?? []

                taxMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        groupDropdownOptions: groupDropdownOption
                    }
                }))
            }
        )
        await customerRepo.initCustomerCountry().then(
            countryList => {
                const countryDropdownOption = countryList?.map((country) => ({
                    dropdownLabel: country,
                    tagLabel: country,
                    value: country,
                })) ?? []

                taxMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        countryDropdownOptions: countryDropdownOption
                    }
                }))
            }
        )
        await consortiumRepo.getAllConsortiums().then(
            consortiums => {
                let consortiumCodeDropdownOptions = consortiums?.map((item) => ({
                    dropdownLabel: item.consortiumCode,
                    tagLabel: item.consortiumCode,
                    value: item.consortiumCode,
                })) ?? []
                consortiumCodeDropdownOptions = _.orderBy(consortiumCodeDropdownOptions, "dropdownLabel");

                taxMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        consortiumCodeDropdownOptions: consortiumCodeDropdownOptions,
                    }
                }))
            }
        );

    }

    const searchAllTaxMappingList = async () => {
        taxMappingDispatch(prevState => {
            return {
                ...prevState,
                currentRow: { ...EMPTY_TAX_MAPPING_ENTITY },
                currentEditRow: { ...EMPTY_TAX_MAPPING_ENTITY },
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
                updatedRows: [],
                taxMappingList: [],
            }
        })
        await taxMappingRepo.searchAllTaxMappingList().then(data => {
            if (data) {
                taxMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        taxMappingList: data,
                    }
                })
            } else {
                taxMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        taxMappingList: [],
                    }
                })
            }
        })
    }

    // const onRowDrag = (rows: TaxMappingEntity[]) => {
    //     taxMappingDispatch(prevState =>{ 
    //         const prevRows = prevState.taxMappingList;
    //         const toChangedRows = rows?.map((e, index) => ({...e,tempPriority: prevRows[index].priority}))?.filter(e=>e.tempPriority!==e.priority);      
    //         return ({
    //             ...prevState,
    //             taxMappingList: rows,
    //             updatedRows: toChangedRows,
    //     })})
    // }

    const onApply = async (updatedRows: TaxMappingEntity[]) => {

        return await taxMappingRepo.onApply(updatedRows);
    }

    const updateSelectedRows = async (rows: TaxMappingEntity[]) => {

        taxMappingDispatch(prevState => {
            return {
                ...prevState,
                // taxMappingList: [...allRows],
                selectedRows: rows,
                forceRefresh: !prevState.forceRefresh
            }
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;

        if (fieldKey === 'taxCode') {
            taxMappingDispatch(prevState => {
                return {
                    ...prevState,
                    currentEditRow: {
                        ...prevState.currentEditRow,
                        percentage: prevState.vatList[val],
                    },
                }
            })
        }
        if (fieldKey === 'chargeType') {
            taxMappingDispatch(prevState => {
                return {
                    ...prevState,
                    currentEditRow: {
                        ...prevState.currentEditRow,
                        subChargeType: val === prevState.currentEditRow.chargeType ? prevState.currentEditRow.subChargeType : ''
                    },
                }
            })
        }
        if (fieldKey === 'tariffType') {
            taxMappingDispatch(prevState => {
                return {
                    ...prevState,
                    currentEditRow: {
                        ...prevState.currentEditRow,
                        tariffCode: val === prevState.currentEditRow.tariffType ? prevState.currentEditRow.tariffCode : ''
                    },
                }
            })
        }
        if (fieldKey === 'chargeTariffType') {
            taxMappingDispatch(prevState => {
                return {
                    ...prevState,
                    currentEditRow: {
                        ...prevState.currentEditRow,
                        chargeTariffCode: val === prevState.currentEditRow.chargeTariffType ? prevState.currentEditRow.chargeTariffCode : ''
                    },
                }
            })
        }
        taxMappingDispatch(prevState => {
            if (fieldKey === 'percentage' || fieldKey === 'paymentTerm' || fieldKey === 'paymentTermCredit' || fieldKey === 'paymentTermDebit') {
                var posIntReg = /^\d*(\.\d{0,2})?$/;
                if (!posIntReg.test(val)) {
                    val = prevState.currentEditRow[fieldKey];
                }
            }
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onRowDoubleClick = async (entity: TaxMappingEntity) => {
        taxMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: entity,
                currentRow: entity,
                isShowEditPanel: true,
            }
        })
    }

    const onCloseEidtPanel = async () => {
        taxMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: { ...EMPTY_TAX_MAPPING_ENTITY },
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
            }
        })
    }

    const onSave = async (currentRow: TaxMappingEntity) => {
        return taxMappingRepo.onSave(currentRow);
    }

    const onAddClick = async () => {
        taxMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: { ...EMPTY_TAX_MAPPING_ENTITY },
                isAdd: true,
                isShowEditPanel: true,
            }
        })
    }

    const onUpload = async (file: File) => {

        const arrayBuffer = await readFileAsArrayBuffer(file);
        const taxMappingEntities = await processFileData(arrayBuffer);

        return await taxMappingRepo.uploadData(taxMappingEntities);
    }

    const readFileAsArrayBuffer = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (e) => resolve(e.target?.result);
            reader.onerror = (e) => reject(e.target?.error);

            reader.readAsArrayBuffer(file);
        });
    }


    const processFileData = async (arrayBuffer: any): Promise<TaxMappingEntity[]> => {
        const byteArray = new Uint8Array(arrayBuffer);
        const decoder = new TextDecoder('utf-8');
        const content = decoder.decode(byteArray);
        const BOM = '\uFEFF';
        const contentWithoutBOM = content.startsWith(BOM) ? content.slice(1) : content;
        const lines = contentWithoutBOM.split('\n')?.map(line => line.trim());
        // const lines = content.split('\n')?.map(line => line.trim());

        const result: TaxMappingEntity[] = [];

        lines?.forEach(line => {
            const obj: TaxMappingEntity = { ...EMPTY_TAX_MAPPING_ENTITY };
            const lineObjArr = line.split(',');
            for (let i = 0; i < taxMappingCSVColumnMapping.length && i < lineObjArr.length; i++) {
                const taxColumnName = taxMappingCSVColumnMapping[i];
                const taxColumnVal = lineObjArr[i];
                //Check if it is of type number
                if (taxMappingCSVNumberColumnMapping.includes(taxColumnName)) {
                    obj[taxColumnName] = _.isEmpty(taxColumnVal) || taxColumnVal === null ? null : parseFloat(taxColumnVal.replace(/^"+|"+$/g, ''));
                    //Check if it is of type Date
                } else if (taxMappingCSVDateColumnMapping.includes(taxColumnName)) {
                    const cleanedValue = _.isEmpty(taxColumnVal) ? null : taxColumnVal.replace(/^"+|"+$/g, '');
                    if (cleanedValue === null) {
                        obj[taxColumnName] = null;
                    } else {
                        try {
                            const date = new Date(Date.parse(cleanedValue.substring(0, 10)));
                            obj[taxColumnName] = _.isEmpty(date) ? null : date;
                        } catch (error) {
                            obj[taxColumnName] = null;
                        }
                    }
                } else {
                    obj[taxColumnName] = _.isEmpty(taxColumnVal) ? null : taxColumnVal;
                }
            }
            result.push(obj);
        });

        return result;
    }
    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        taxMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldName]: checked ? "Y" : "N",
                },
            }
        })
    }

    const onResetClick = async (isAdd: boolean) => {
        taxMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: isAdd ? { ...EMPTY_TAX_MAPPING_ENTITY } : prevState.currentRow,
            }
        })
    }
    const onEditClick = async () => {
        taxMappingDispatch(prevState => {
            return {
                ...prevState,
                isEdit: true,
            }
        })
    }
    const onTariffCodeItemChanged = async (tariffType: string, tariffCode: string, fieldName: string) => {
        if (!tariffType || !tariffCode) {
            return;
        }
        await standardTariffCodeRepo.getTariffByTypeAndCode(tariffType, tariffCode).then((data) => {
            taxMappingDispatch(prevState => {
                return {
                    ...prevState,
                    currentEditRow: {
                        ...prevState.currentEditRow,
                        [fieldName]: data?.tariffCodeDesc ?? "",
                    },
                }
            })
        })
    }

    const onTaxCodeItemChanged = async (taxCode: string, fieldName: string) => {
        taxMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldName]: prevState.transactionTypeList[taxCode],
                },
            }
        })
    }

    const onArticleStatementCodeItemChanged = async (articleStatementCode: string, fieldName: string) => {
        taxMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldName]: prevState.articleStatementList[articleStatementCode],
                },
            }
        })
    }
    const onRefreshDefaultValue = async () => {
        taxMappingDispatch(prevState => {
            const tariffCode = prevState.dynamicOptions.tariffCodeDropdownOptions[prevState.dynamicOptions.tariffTypeDropdownOptions[0].value] ?? [];
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    taxCode: prevState.dynamicOptions.taxCodeDropdownOptions[0].value,
                    paymentReferenceDate: vatPaymentReferenceDateDropdownOption[0].value,
                    paymentReferenceDateCredit: vatPaymentReferenceDateDropdownOption[0].value,
                    paymentReferenceDateDebit: vatPaymentReferenceDateDropdownOption[0].value,
                    statementCode: prevState.dynamicOptions.vatArticleStatementCodeDropdownOptions[0].value,
                    tariffType: prevState.dynamicOptions.tariffTypeDropdownOptions[0].value,
                    tariffCode: tariffCode.length > 0 ? tariffCode[0].value : '',
                },
            }
        })
    }

    return {
        onRefreshDefaultValue: onRefreshDefaultValue,
        onArticleStatementCodeItemChanged: onArticleStatementCodeItemChanged,
        onTaxCodeItemChanged: onTaxCodeItemChanged,
        onTariffCodeItemChanged: onTariffCodeItemChanged,
        onEditClick: onEditClick,
        onResetClick: onResetClick,
        onCheckboxChange: onCheckboxChange,
        onUpload: onUpload,
        onAddClick: onAddClick,
        onSave: onSave,
        onCloseEidtPanel: onCloseEidtPanel,
        onRowDoubleClick: onRowDoubleClick,
        onHeaderFieldChange: onHeaderFieldChange,
        updateSelectedRows: updateSelectedRows,
        onApply: onApply,
        // onRowDrag: onRowDrag,
        loadDropdownOption: loadDropdownOption,
        searchAllTaxMappingList: searchAllTaxMappingList,
    }
}