import { SelectionChangedEvent } from "ag-grid-community";
import { TaxMappingEntity } from "domain/entity/TaxMapping/TaxMappingEntity";
import _ from "lodash";
import { INITIAL_TAX_MAPPING_COL_DEF } from "presentation/constant/TaxMapping/TaxMappingColumnDefinition";
import { useTaxMappingVM } from "presentation/hook/TaxMapping/useTaxMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTaxMappingTracked } from "presentation/store/TaxMapping/TaxMappingProvider";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
const TaxMappingTablePanel = () => {
    const gridRef: any = useRef(null);
    const [taxMappingState] = useTaxMappingTracked();
    const taxMappingVM = useTaxMappingVM();
    const messageBarVM = useMessageBarVM();

    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const rightClickRef: any = createRef();
    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<TaxMappingEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        taxMappingVM.updateSelectedRows(selectedRows);
    }, [taxMappingVM])


    const handleRowDoubleClick = useCallback((entity: TaxMappingEntity) => {
        taxMappingVM.onRowDoubleClick(entity);
    }, [taxMappingVM])

    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const getSelectedRows = useCallback(() => {
        return taxMappingState.selectedRows;
    }, [taxMappingState.selectedRows]);

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(taxMappingState.selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, taxMappingState.selectedRows]);

    // for right click menu with move after begin
    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        taxMappingVM.updateSelectedRows([]);
        setShowMoveCursor(true);
    }, [taxMappingVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(taxMappingState.taxMappingList);
        taxMappingVM.updateSelectedRows([]);
        setShowMoveCursor(false);
    }, [taxMappingState.taxMappingList, taxMappingVM]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;

        setIsLoading(true);

        taxMappingVM.onApply(newAllRows).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showError('Move failed.');
                setInitialAllRows(true);
                setIsLoading(false);

                taxMappingVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            } else {
                taxMappingVM.searchAllTaxMappingList().then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    taxMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    taxMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [messageBarVM, taxMappingVM]);

    const memoTaxMappingTable = useMemo(() => {

        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="priority" />}
                <div id="myDiv">
                    <NbisTable
                        id='tax-mapping-table'
                        isNewColumnSetting={true}
                        columns={INITIAL_TAX_MAPPING_COL_DEF}
                        data={allRows ?? []}
                        showPaginator={false}
                        editable={false}
                        showAddIcon={false}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        selectionMode={true}
                        showCutCursor={showMoveCursor}
                        isRowHighligted={true}
                        handleSelectionChanged={handleSelectionChange}
                        onRowDoubleClick={(e: any, entity: TaxMappingEntity) => handleRowDoubleClick(entity)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 125px)"
                        ref={gridRef}
                        // isRowDrag={true}
                        // onRowDragged={handleRowDrag}
                        rowSelection={showMoveCursor ? "single" : "multiple"}
                    />
                </div>
            </>
        );
    }, [allowUpdate, getSelectedRows, rightClickRef, getAllRows, onSaveMove, onMoveCancel, onRefreshRow, showMoveCursor, allRows, handleSelectionChange, handleRowDoubleClick])

    useEffect(() => {
        if (initialAllRows && taxMappingState.taxMappingList && !_.isEmpty(taxMappingState.taxMappingList)) {
            setAllRows(taxMappingState.taxMappingList?.map((taxMappingEntity, index) => ({
                ...taxMappingEntity,
                index: index || 0,
                flag_showCutCursor: false
            })));
            setInitialAllRows(false);
        }
    }, [allRows, initialAllRows, taxMappingState.taxMappingList]);

    return <><TableWrapper>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        {memoTaxMappingTable}
        {taxMappingState.forceRefresh && <></>}
    </TableWrapper>
    </>;
}

export default memo(TaxMappingTablePanel);
